<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm" :method="store"/>
      <CCard class="border-warning">
        <CCardHeader>
          <strong class="text-primary">
            Registro de Factura <span class="text-success">Cliente Externo</span>
          </strong>
        </CCardHeader>
        <CCardBody class="p-2">
          <form ref="formCreate" novalidate>
            <div class="row mb-1">
              <div class="col-3">
                <label class="form-label" for="factura_nit">Nit Cliente</label>
                <input id="factura_nit" v-model="factura.factura_nit" class="form-control"
                       type="text" @change="buscarnit()" required="required"/>
              </div>
              <div class="col-5">
                <label class="form-label" for="factura_nombre">Nombre cliente:</label>
                <input id="factura_nombre" v-model="factura.factura_nombre"
                       @change="factura.factura_nombre=factura.factura_nombre.toUpperCase()"
                       class="form-control" type="text" required="required"/>
              </div>
              <div class="col-2">
                <label class="form-label" for="fechapago">Fecha</label>
                <input id="fechapago" class="form-control" readonly type="text" :value="factura_fecha">
              </div>
              <div class="col-2">
                <label class="form-label" for="horapago">Hora</label>
                <input id="horapago" class="form-control" readonly type="text" :value="factura_hora">
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-3">
                <label class="form-label" for="estudiante_ci">CI/Pasaporte del Estudiante</label>
                <input id="estudiante_ci" v-model="factura.estudiante_ci" class="form-control"
                       type="text" @change="buscarci()"/>
              </div>
              <div class="col-5">
                <label class="form-label" for="estudiante_nombre">Nombre del Estudiante:</label>
                <input id="estudiante_nombre" v-model="factura.estudiante_nombre"
                       class="form-control" type="text"/>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <label class="form-label" for="gestion">Gestión:</label>
                <select class="form-control" id="gestion"
                        v-model="factura.gestion" required="required">
                  <template v-for="(option,key) in gestiones">
                    <optgroup :label="key">
                      <option v-for="suboption in option" :value="suboption.value">
                        {{ suboption.label }}
                      </option>
                    </optgroup>
                  </template>
                </select>
              </div>
              <div class="col-2">
                <CSelect
                    :options="moneda"
                    :plain="true"
                    :value.sync="factura.moneda"
                    label="Moneda:"
                    readonly="readonly"
                >
                </CSelect>
              </div>
              <div class="col-2">
                <label class="form-label" for="tipocambio">Tipo de cambio:</label>
                <input id="tipocambio" v-model="factura.tipo_cambio" class="form-control" readonly type="text">
              </div>
              <div class="col-3">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id"
                    label="Tipo de Cobro"
                    required="required"
                    @change="actualizarpagos"
                >
                </CSelect>
              </div>
              <div class="col-3" v-if="factura.tipo_cobro_id!=tipoEfectivo">
                <label class="form-label" for="tipocambio">Deposito:</label>
                <input id="deposito" v-model="factura.deposito" class="form-control"
                       type="text" @change="actualizarDepositos">
              </div>
            </div>
            <hr class="mt-1 mb-1"/>
            <div class="row pb-0 mb-0">
              <div class="col-1 text-center">
                Item
              </div>
              <div class="col-3 pl-0 pr-0 text-center">
                A cobrar
              </div>
              <div class="col-2 text-center">
                Monto
                <font-awesome-icon icon="fa-solid fa-circle-question"
                                   v-c-tooltip="'Si los montos de las <u>cuotas</u> (<u>1,2,3,4,5</u>) no son correctos debe actualizar el <b>contrato</b> del estudiante'"/>
              </div>
              <div class="col-1 pl-0 pr-0 text-center">
                T. Cobro
              </div>
              <div class="col-2 pl-0 pr-0 text-center">
                Depósito
              </div>
              <div class="col-2 text-center">
                Observaciones
              </div>
              <div class="col-1 pl-0">
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-1 text-center pl-0 pr-1">1</div>
              <div class="col-3 pl-0 pr-1">
                <CSelect
                    :options="conceptoPago"
                    :plain="true"
                    :value.sync="factura.concepto_id_1"
                    label=""
                    @change="actualizaMonto(1)"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input id="monto_1" v-model="factura.monto_1" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_1 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
              </div>
              <div class="col-1 pl-0 pr-1">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_1"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <template >
                  <input id="deposito_1" v-model="factura.deposito_1" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_1 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input id="observacion_1" v-model="factura.observacion_1"
                       class="form-control" maxlength="350"
                       type="text">
              </div>
              <div class="col-1 pl-0">
                <button :class="factura.monto_1 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_1 < 1" @click="limpiar(1)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-1 text-center pl-0 pr-1">2</div>
              <div class="col-3 pl-0 pr-1">
                <CSelect
                    :options="conceptoPago"
                    :plain="true"
                    :value.sync="factura.concepto_id_2"
                    label=""
                    @change="actualizaMonto(2)"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.monto_2" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_2 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
              </div>
              <div class="col-1 pl-0 pr-1">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_2"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <template >
                  <input id="deposito_2" v-model="factura.deposito_2" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_2 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.observacion_2" class="form-control" maxlength="350" type="text">
              </div>
              <div class="col-1 pl-0 pr-1">
                <button :class="factura.monto_2 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_2 < 1" @click="limpiar(2)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-1 text-center pl-0 pr-1">3</div>
              <div class="col-3 pl-0 pr-1">
                <CSelect
                    :options="conceptoPago"
                    :plain="true"
                    :value.sync="factura.concepto_id_3"
                    label=""
                    @change="actualizaMonto(3)"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.monto_3" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_3 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
              </div>
              <div class="col-1 pl-0 pr-1">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_3"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <template >
                  <input id="deposito_3" v-model="factura.deposito_3" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_3 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.observacion_3" class="form-control" maxlength="350" type="text">
              </div>
              <div class="col-1 pl-0 pr-1">
                <button :class="factura.monto_3 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_3 < 1" @click="limpiar(3)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-1 text-center pl-0 pr-1">4</div>
              <div class="col-3 pl-0 pr-1">
                <CSelect
                    :options="conceptoPago"
                    :plain="true"
                    :value.sync="factura.concepto_id_4"
                    label=""
                    @change="actualizaMonto(4)"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.monto_4" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_4 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
              </div>
              <div class="col-1 pl-0 pr-1">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_4"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <template >
                  <input id="deposito_4" v-model="factura.deposito_4" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_4 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.observacion_4" class="form-control" maxlength="350" type="text">
              </div>
              <div class="col-1 pl-0 pr-1">
                <button :class="factura.monto_4 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_4 < 1" @click="limpiar(4)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-1 text-center pl-0 pr-1">5</div>
              <div class="col-3 pl-0 pr-1">
                <CSelect
                    :options="conceptoPago"
                    :plain="true"
                    :value.sync="factura.concepto_id_5"
                    label=""
                    @change="actualizaMonto(5)"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.monto_5" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_5 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
              </div>
              <div class="col-1 pl-0 pr-1">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_5"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <template >
                  <input id="deposito_5" v-model="factura.deposito_5" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_5 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.observacion_5" class="form-control" maxlength="350" type="text">
              </div>
              <div class="col-1 pl-0 pr-1">
                <button :class="factura.monto_5 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_5 < 1" @click="limpiar(5)"
                >Limpiar
                </button>
              </div>
            </div>
            <div class="row pb-0 mb-0">
              <div class="col-1 text-center pl-0 pr-1">6</div>
              <div class="col-3 pl-0 pr-1">
                <CSelect
                    :options="conceptoPago"
                    :plain="true"
                    :value.sync="factura.concepto_id_6"
                    label=""
                    @change="actualizaMonto(6)"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.monto_6" autocomplete="false"
                       class="" step="0.1" type="number"
                       v-bind:class="[factura.monto_6 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
              </div>
              <div class="col-1 pl-0 pr-1">
                <CSelect
                    :options="tipoCobro"
                    :plain="true"
                    :value.sync="factura.tipo_cobro_id_6"
                    label=""
                    required="required"
                    class=" pb-0 mb-1"
                >
                </CSelect>
              </div>
              <div class="col-2 pl-0 pr-1">
                <template >
                  <input id="deposito_6" v-model="factura.deposito_6" class="form-control"
                         type="text" v-if="factura.tipo_cobro_id_6 !== tipoEfectivo">
                </template>
              </div>
              <div class="col-2 pl-0 pr-1">
                <input v-model="factura.observacion_6" class="form-control" maxlength="350" type="text">
              </div>
              <div class="col-1 pl-0 pr-1">
                <button :class="factura.monto_6 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                        :disabled="factura.monto_6 < 1" @click="limpiar(6)"
                >Limpiar
                </button>
              </div>
            </div>
          </form>
        </CCardBody>
        <CCardFooter>
          <CButton class="mr-2" color="primary" @click="confirmarGrabar()">Generar Factura (Imprimir)</CButton>
          <CButton color="danger" @click="goBack">Salir sin generar factura</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import Table from "@/views/base/Table";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";

var sourceLst;
var moment = require('moment');
var modalPdf;

export default {
  name: 'EditUser',
  components: {
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    Table,
  },
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      inscripcion: {
        facultad_id: 0,
        carrera_id: 0,
        codigo_alumno: '',
        gestion: '',
        apellido1: '',
        apellido2: '',
        nombres: '',
        genero_id: 0,
        fecha_nacimiento: '',
        nacionalidad_id: 0,
        documento_identificacion: '',
        traspaso_id: 0,
        estado_civil_id: 0,
        trabaja_id: 0,
        direccion: '',
        telefono: '',
        email: '',
        casilla: '',
      },
      facultades: [], carreras: [],
      generos: [], estados_civiles: [],
      nacionalidades: [], si_nos: [],
      maxdate: moment(new Date()).add(-12 * 10, 'M').format("YYYY-MM-DD"),
      factura_fecha: moment(new Date()).format("YYYY-MM-DD"),
      factura_hora: moment(new Date()).format("HH:mm"),

      multa: 2,tipoEfectivo: 0,
      cuota1: {fecha: '', diasmulta: '', multa: ''},
      cuota2: {fecha: '', diasmulta: '', multa: ''},
      cuota3: {fecha: '', diasmulta: '', multa: ''},
      cuota4: {fecha: '', diasmulta: '', multa: ''},
      cuota5: {fecha: '', diasmulta: '', multa: ''},

      factura: {
        factura_nit: '',
        factura_nombre: '',
        estudiante_ci: '',
        estudiante_nombre: '',
        carrera_id: 0,
        codigo_alumno: '',
        gestion: '',
        moneda_id: 0,
        tipo_cambio: 0,
        semestre: '',
        tipo_cobro_id: 0,
        sede_id: 0,
        deposito: '',
        concepto_id_1: 0, monto_1: '', observacion_1: '', tipo_cobro_id_1:0, deposito_1:'',
        concepto_id_2: 0, monto_2: '', observacion_2: '', tipo_cobro_id_2:0, deposito_2:'',
        concepto_id_3: 0, monto_3: '', observacion_3: '', tipo_cobro_id_3:0, deposito_3:'',
        concepto_id_4: 0, monto_4: '', observacion_4: '', tipo_cobro_id_4:0, deposito_4:'',
        concepto_id_5: 0, monto_5: '', observacion_5: '', tipo_cobro_id_5:0, deposito_5:'',
        concepto_id_6: 0, monto_6: '', observacion_6: '', tipo_cobro_id_6:0, deposito_6:'',
      },
      conceptoPago: [], moneda: [], tipoCobro: [], gestiones: [],

      items: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/users'})
    },
    buscarnit() {
      axios.get(
          modalPdf.$apiAdress + '/api/factura/' + modalPdf.factura.factura_nit + '/buscarnit?token=' + localStorage.getItem("api_token")
      )
          .then((response) => {
            modalPdf.factura.factura_nombre = response.data;
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    buscarci() {
      axios.get(
          modalPdf.$apiAdress + '/api/factura/' + modalPdf.factura.estudiante_ci + '/buscarci?token=' + localStorage.getItem("api_token")
      )
          .then((response) => {
            modalPdf.factura.estudiante_nombre = response.data;
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    confirmarGrabar() {
      if (!modalPdf.$refs.formCreate.checkValidity()) {
        modalPdf.$refs.formCreate.classList.add('was-validated');
        modalPdf.$refs.mensajeToast.makeToast('Error', 'Algunos datos son requeridos.', 'danger');
      } else {
        modalPdf.$refs.confirm.confirm('¿Esta segur@ de grabar la factura?', 'Después de este paso no podrá realizar más cambios',
            modalPdf.store,function (){});
      }
    },
    store() {
      modalPdf.factura.codigo_alumno = modalPdf.inscripcion.codigo_alumno;
      modalPdf.factura.carrera_id = modalPdf.inscripcion.carrera_id;
      modalPdf.factura.sede_id = modalPdf.sedeSeleccionada;
      axios.post(this.$apiAdress + '/api/facturaexterna/store?token=' + localStorage.getItem("api_token"),
          modalPdf.factura
      )
          .then(function (response) {
            modalPdf.inscripcion = {
              factura_nit: '',
              factura_nombre: '',
              estudiante_ci: '',
              estudiante_nombre: '',
              carrera_id: 0,
              codigo_alumno: '',
              gestion: '',
              moneda_id: 0,
              tipo_cambio: 0,
              semestre: '',
              tipo_cobro_id: 0,
              sede_id: 0,
              deposito: '',
              concepto_id: 0, monto: '', observacion: '',
              concepto_id_2: 0, monto_2: '', observacion_2: '',
              concepto_id_3: 0, monto_3: '', observacion_3: '',
              concepto_id_4: 0, monto_4: '', observacion_4: '',
              concepto_id_5: 0, monto_5: '', observacion_5: '',
              concepto_id_6: 0, monto_6: '', observacion_6: '',
            };
            modalPdf.$refs.mensajeToast.makeToast('Info', 'Factura creada satisfactoriamente.', 'success');
            modalPdf.$refs.alert.hide();
            modalPdf.reportePdf(response.data.factura_id);
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    buscaMonto(concepto_id) {
      switch (concepto_id) {
        default:
          return '';
          break;
      }
    },
    actualizaMonto(item) {
      this.observacion = '';
      switch (item) {
        case 1:
          this.factura.monto_1 = this.buscaMonto(this.factura.concepto_id_1);
          this.factura.observacion_1 = this.observacion;
          break;
        case 2:
          this.factura.monto_2 = this.buscaMonto(this.factura.concepto_id_2);
          this.factura.observacion_2 = this.observacion;
          break;
        case 3:
          this.factura.monto_3 = this.buscaMonto(this.factura.concepto_id_3);
          this.factura.observacion_3 = this.observacion;
          break;
        case 4:
          this.factura.monto_4 = this.buscaMonto(this.factura.concepto_id_4);
          this.factura.observacion_4 = this.observacion;
          break;
        case 5:
          this.factura.monto_5 = this.buscaMonto(this.factura.concepto_id_5);
          this.factura.observacion_5 = this.observacion;
          break;
        case 6:
          this.factura.monto_6 = this.buscaMonto(this.factura.concepto_id_6);
          this.factura.observacion_6 = this.observacion;
          break;
      }
    },
    actualizarpagos(){
      this.factura.tipo_cobro_id_1=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_2=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_3=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_4=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_5=this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_6=this.factura.tipo_cobro_id;
    },
    actualizarDepositos(){
      this.factura.deposito_1=this.factura.deposito;
      this.factura.deposito_2=this.factura.deposito;
      this.factura.deposito_3=this.factura.deposito;
      this.factura.deposito_4=this.factura.deposito;
      this.factura.deposito_5=this.factura.deposito;
      this.factura.deposito_6=this.factura.deposito;
    },
    reportePdf(factura_id) {
      modalPdf.$refs.alert.show('Descargando documento');
      axios.get(
          modalPdf.$apiAdress + '/api/factura/' + factura_id + '/facturapdf?token=' + localStorage.getItem("api_token"),
          {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
          })
          .catch(function (error) {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    limpiar(fila) {
      switch (fila) {
        case 0:
          this.factura.concepto_id = "";
          this.factura.monto = "";
          this.factura.observacion = "";
          break;
        case 2:
          this.factura.concepto_id_2 = "";
          this.factura.monto_2 = "";
          this.factura.observacion_2 = "";
          break;
        case 3:
          this.factura.concepto_id_3 = "";
          this.factura.monto_3 = "";
          this.factura.observacion_3 = "";
          break;
        case 4:
          this.factura.concepto_id_4 = "";
          this.factura.monto_4 = "";
          this.factura.observacion_4 = "";
          break;
        case 5:
          this.factura.concepto_id_5 = "";
          this.factura.monto_5 = "";
          this.factura.observacion_5 = "";
          break;
        case 6:
          this.factura.concepto_id_6 = "";
          this.factura.monto_6 = "";
          this.factura.observacion_6 = "";
          break;
      }
    },
  },
  mounted: function () {
    modalPdf = this;
    axios.post(this.$apiAdress + '/api/facturaexterna/' + localStorage.sedeSeleccionada + '/opcionesfactura?token=' + localStorage.getItem("api_token"),
        {'tipo_lista':'inscripcion_lista'}
    )
        .then(function (response) {
          modalPdf.moneda = response.data.moneda;
          let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
          modalPdf.conceptoPago = valorPorDefecto.concat(response.data.conceptoPago);
          modalPdf.tipoCobro = response.data.tipoCobro;
          for (let tip in modalPdf.tipoCobro) {
            if (modalPdf.tipoCobro[tip].label == "Efectivo") {
              modalPdf.tipoEfectivo = modalPdf.tipoCobro[tip].value
              modalPdf.factura.tipo_cobro_id_1= modalPdf.tipoCobro[tip].value
              modalPdf.factura.tipo_cobro_id_2= modalPdf.tipoCobro[tip].value
              modalPdf.factura.tipo_cobro_id_3= modalPdf.tipoCobro[tip].value
              modalPdf.factura.tipo_cobro_id_4= modalPdf.tipoCobro[tip].value
              modalPdf.factura.tipo_cobro_id_5= modalPdf.tipoCobro[tip].value
              modalPdf.factura.tipo_cobro_id_6= modalPdf.tipoCobro[tip].value
            }
          }
          modalPdf.gestiones = response.data.gestiones;
          modalPdf.factura.moneda_id = response.data.moneda[0].value;
          modalPdf.factura.concepto_id = response.data.conceptoPago[0].value;
          modalPdf.factura.tipo_cobro_id = response.data.tipoCobro[0].value;
          modalPdf.factura.gestion = response.data.mesActual;
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}
</script>